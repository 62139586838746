import React, { useState } from 'react';
import Modal from 'react-modal';
import './App.css';
import logo from './flowon-logo/flowon-spinner-v2.gif';
import { FaEnvelope } from 'react-icons/fa'; // Import the message icon

Modal.setAppElement('#root');

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    alert('Thank you! We will notify you.');
    closeModal();
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Loading..." />
        <h1>We're Still Cooking Our Website</h1>
        <p className="catchy-sentence">Something awesome is coming your way. Stay tuned!</p>
        {/* <button onClick={openModal} className="notify-button">
          <FaEnvelope className="button-icon" />
          Notify Me
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="Modal"
          overlayClassName="Overlay"
        >
          <button onClick={closeModal} className="close-button">
            &times;
          </button>
          <h2>Stay Updated!</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </Modal> */}
      </header>
    </div>
  );
}

export default App;
